import { HeaderActionItemType } from './index';
import TestDriveIcon from './assets/test-drive.svg';

export const vehicles: HeaderActionItemType[] = [
  {
    imageSrc: 'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/cupra-born_wx1Ce0uRN0.png?updatedAt=1702428799394',
    label: 'Born',
    href: 'https://www.cupraofficial.co.nz/cars/cupra-range/born',
  },
  {
    imageSrc: 'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/cupra-leon-sp_v8nGFwFRI.png?updatedAt=1702428799389',
    label: 'Leon',
    href: 'https://www.cupraofficial.co.nz/cars/cupra-range/leon',
  },
  {
    imageSrc: 'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/cupra-leon-st_BbKY3WBi_.png?updatedAt=1702428799084',
    label: 'Leon Sportstourer',
    href: 'https://www.cupraofficial.co.nz/cars/cupra-range/leon-sportstourer',
  },
  {
    imageSrc: 'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/cupra-ateca_VKIY8g__oN.png?updatedAt=1702428799585',
    label: 'Ateca',
    href: 'https://www.cupraofficial.co.nz/cars/cupra-range/ateca',
  },
  {
    imageSrc: 'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/cupra-formentor_OxX6XJ2e2.png?updatedAt=1702428799572',
    label: 'Formentor',
    href: 'https://www.cupraofficial.co.nz/cars/cupra-range/formentor',
  },
  {
    imageSrc: 'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/cupra-tavascan_mJ9cMTjGZ8.png?updatedAt=1702428799583',
    label: 'Tavascan',
    href: 'https://www.cupraofficial.co.nz/cars/tavascan',
  },
];

export const upperHeaderLinks: HeaderActionItemType[] = [
  { label: 'Book a Test Drive', href: 'https://www.cupraofficial.co.nz/test-drive-a-cupra', imageSrc: TestDriveIcon },
  { label: 'Available Stock', href: '/vehicles/list/new', imageSrc: null },
  { label: 'Latest Offers', href: 'https://www.cupraofficial.co.nz/services/offers', imageSrc: null },
];
