import { useAuth } from '@vyro-x/react-auth';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { AiFillDashboard, AiOutlineLogout, AiOutlineUser } from 'react-icons/ai';
import { FaRegUserCircle } from 'react-icons/fa';
import { RiAdminFill } from 'react-icons/ri';
import { useShowroom } from '../../../../services/showrooms/useShowroom';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { useOnClickOutside } from '../../../../services/useOnClickOutside';
import AuthDrawer from '../../../AuthDrawer';
import Button from '../../../Button';
import { ComponentBaseProps } from '../../types';
import styles from './index.module.scss';

export type Props = {} & ComponentBaseProps;

export default function AccountMenu(props: Props) {
  const { className, itemsClassName, isAccountMenuOpen: isOpen, setIsAccountMenuOpen: setIsOpen } = props;

  const router = useRouter();
  const showroom = useShowroom();
  const showroomSettings = useShowroomSettings(['feature_login_enabled']);

  const { isAuthenticated, roles } = useAuth();
  const hasAdminRole = roles.includes('admin');
  const hasShowroomRole = roles.some((role) => role.startsWith('showroom:'));

  const [authAction, setAuthAction] = useState<'login' | 'signup'>('login');
  const [isAuthDrawerOpen, setIsAuthDrawerOpen] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  const dashboardUrl = (() => {
    const url = new URL(process.env.NEXT_PUBLIC_DASHBOARD_URL);

    url.pathname = `/showrooms/${showroom.code}/overview`;

    return url.toString();
  })();

  const items = [
    hasAdminRole && {
      href: '/admin',
      label: 'Admin',
      icon: <RiAdminFill />,
      prefetch: false,
    },
    (hasAdminRole || hasShowroomRole) && {
      href: dashboardUrl,
      label: 'Dashboard',
      icon: <AiFillDashboard />,
      prefetch: false,
    },
    {
      href: '/account',
      label: 'My account',
      icon: <AiOutlineUser />,
      prefetch: false,
    },
    {
      href: '/auth/logout',
      label: 'Logout',
      icon: <AiOutlineLogout />,
      prefetch: false,
    },
  ].filter(Boolean);

  if (!showroomSettings.feature_login_enabled) {
    return null;
  }

  return (
    <>
      {isAuthenticated ? (
        <div className={classnames(styles.account, isOpen && styles.isOpen, className)} ref={ref}>
          <button className={styles.accountToggle} onClick={() => setIsOpen(!isOpen)}>
            <FaRegUserCircle />
          </button>
          <div className={classnames(styles.items, itemsClassName)}>
            {items.map((item, i) => (
              <Link key={i} href={item.href} prefetch={item.prefetch || true}>
                <a className={classnames(styles.item, router.pathname === item.href && styles.isActive)}>
                  {item.icon}
                  {item.label}
                </a>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <div className={classnames(styles.loginOrSignup, className)}>
          <Button
            isText
            className={styles.login}
            onClick={() => {
              setAuthAction('login');
              setIsAuthDrawerOpen(true);
            }}
          >
            Login
          </Button>
          <Button
            className={styles.join}
            size="small"
            color="grey"
            onClick={() => {
              setAuthAction('signup');
              setIsAuthDrawerOpen(true);
            }}
            data-test="signup-button"
          >
            Join
          </Button>
        </div>
      )}

      <AuthDrawer action={authAction} isOpen={isAuthDrawerOpen} onClose={() => setIsAuthDrawerOpen(false)} />
    </>
  );
}
