import classnames from 'classnames';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { useShowroomSettings } from '../../../../../../services/showrooms/useShowroomSettings';
import { useOnClickOutside } from '../../../../../../services/useOnClickOutside';
import { MenuItem, MenuItemWithSections, MenuItemWithSubMenus } from '../../../../../../types/menu';
import Button from '../../../../../Button';
import Markdown from '../../../../../Markdown';
import { CollapsableMenuProps } from '../../types';
import styles from './index.module.scss';

export default function MultiLayeredCollapsableMenu(props: CollapsableMenuProps) {
  const { className, navClassName, itemsClassName, menuToggleRef, size, isMainMenuOpen, setIsMainMenuOpen } = props;

  const showroomSettings = useShowroomSettings(['header_menu']);

  const items: Array<MenuItem> = showroomSettings.header_menu || [];

  const defaultMenuState = {
    _0: true,
  };

  const ref = useRef(null);
  useOnClickOutside([ref, menuToggleRef], () => {
    setIsMainMenuOpen(false);
    setSubMenuState(defaultMenuState);
  });

  const router = useRouter();
  useEffect(() => {
    resetMenu();
  }, [router.asPath]);

  const resetMenu = () => {
    setIsMainMenuOpen(false);
    setSubMenuState(defaultMenuState);
  };

  const [subMenuState, setSubMenuState] = useState(defaultMenuState);
  const isSubMenuOpen = Object.values(subMenuState).some((isOpen) => isOpen);

  const openSubMenus = Object.values(subMenuState).filter((isOpen) => isOpen);
  const onlyFirstSubMenuIsOpen = isSubMenuOpen && openSubMenus.length === 1 && subMenuState._0 === true;

  useEffect(() => {
    if (isMainMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isMainMenuOpen]);

  const goBack = () => {
    setSubMenuState((prev) => {
      const keys = Object.keys(prev);
      let maxKey = '';
      let selectedCount = 0;
      keys.forEach((key) => {
        if (key.length > maxKey.length && prev[key] === true) {
          maxKey = key;
          selectedCount++;
        }
      });
      return {
        ...prev,
        [maxKey]: false,
        _0: selectedCount <= 1,
      };
    });
  };

  return (
    <div
      className={classnames(
        styles.dropDownCollapsableMenu,
        isMainMenuOpen && styles.isOpen,
        isSubMenuOpen && !onlyFirstSubMenuIsOpen && styles.isSubMenuOpen,
        styles[size],
        className,
      )}
      ref={ref}
    >
      <nav className={classnames(styles.nav, navClassName)}>
        <Control aria-label="Back one level" className={styles.back} onClick={() => goBack()}>
          <Arrow dir={'left'} size={'large'} />
        </Control>

        <Control aria-label="Close" className={styles.close} onClick={() => resetMenu()}>
          <GrClose size={18} />
        </Control>

        <div className={styles.navMenuItemsContainer}>
          <Items
            depth={1}
            items={items}
            itemsClassName={itemsClassName}
            onSubMenuChange={(isOpen, i) => {
              // not supporting unselect

              if (isOpen === false) return;

              setSubMenuState((prev) => {
                const removeCandidatePrefix = i.substring(0, i.lastIndexOf('_'));
                const removeCandidates =
                  removeCandidatePrefix === ''
                    ? Object.keys(prev)
                    : Object.keys(prev).filter((x) => !removeCandidatePrefix.startsWith(x));

                const prevCopy = { ...prev };
                removeCandidates.map((item) => {
                  prevCopy[item] = false;
                });

                return {
                  ...prevCopy,
                  [i]: isOpen,
                };
              });
            }}
            subMenuState={subMenuState}
            columns={1}
            isRelative
          />
        </div>
      </nav>
    </div>
  );
}

type ItemProps = {
  item: MenuItem;
  subMenuIndicator: string;
  onSubMenuChange?: (isOpen: boolean, i: string) => void;
  className?: string;
  subMenuState: Record<string, boolean>;
  depth: number;
};

type ItemsProps = {
  items: MenuItem[];
  onSubMenuChange?: (isOpen: boolean, i: string) => void;
  itemsClassName?: string;
  subMenuIndicator?: string;
  subMenuState: Record<string, boolean>;
  columns?: number;
  coverImage?: string;
  depth: number;
  isRelative?: boolean;
};

function Items(props: ItemsProps) {
  const {
    items,
    onSubMenuChange,
    itemsClassName,
    subMenuIndicator = '',
    subMenuState,
    columns,
    coverImage,
    depth,
    isRelative = false,
  } = props;

  let somethingIsSelected = false;

  items.forEach((_, i) => {
    const newIndicator = `${subMenuIndicator}_${i}`;
    const isSubMenuOpen = Object.keys(subMenuState).some((key) => key.startsWith(newIndicator) && !!subMenuState[key]);
    if (isSubMenuOpen === true) {
      somethingIsSelected = true;
    }
  });

  return (
    <>
      <ul
        className={classnames(
          columns && styles[`ul-col-${columns}`],
          somethingIsSelected && styles.isOpen,
          isRelative && styles.isRelative,
        )}
        style={{ zIndex: depth }}
      >
        {items.map((item, i) => {
          const newIndicator = `${subMenuIndicator}_${i}`;
          return (
            <Item
              subMenuIndicator={newIndicator}
              key={i}
              item={item}
              className={itemsClassName}
              onSubMenuChange={onSubMenuChange}
              subMenuState={subMenuState}
              depth={depth}
            />
          );
        })}
      </ul>
      {coverImage && (
        <img src={coverImage} className={classnames(styles.coverImage, somethingIsSelected && styles.hidden)} />
      )}
    </>
  );
}

function Item(props: ItemProps) {
  const { item, onSubMenuChange, className, subMenuIndicator, subMenuState, depth } = props;

  const hasChildren = item.type === 'parentMenu';
  const isSection = item.type === 'section';

  const isSubMenuOpen = !!subMenuState[subMenuIndicator];

  const router = useRouter();

  if (isSection) {
    return (
      <>
        {(item as MenuItemWithSections).sections.map((section, i) => {
          const newIndicator = `${subMenuIndicator}_${i}`;
          const isSectionOpen = Object.keys(subMenuState).some(
            (key) => key.startsWith(newIndicator) && !!subMenuState[key],
          );

          return (
            <li key={i} className={classnames(styles.item, className, styles.section, isSectionOpen && styles.isOpen)}>
              {section.label && (
                <p className={styles.sectionTitle}>
                  <Markdown>{section.label}</Markdown>
                </p>
              )}
              <Items
                depth={depth + 1}
                items={section.items}
                subMenuState={subMenuState}
                itemsClassName={className}
                onSubMenuChange={onSubMenuChange}
                subMenuIndicator={newIndicator}
                columns={section.columns}
              />
            </li>
          );
        })}
      </>
    );
  }

  return (
    <li
      className={classnames(
        styles.item,
        isSubMenuOpen && hasChildren && styles.isOpen,
        className,
        item.isEmphasised && styles.isEmphasised,
        router.pathname.startsWith(item.href) && styles.isActive,
      )}
      onClick={(e) => {
        e.stopPropagation();
        hasChildren && onSubMenuChange(true, subMenuIndicator);
      }}
    >
      {item.type === 'cta' ? (
        <div>
          <Button isBordered color={'primary'} href={item.href} className="hug">
            <Markdown>{item.label as string}</Markdown>
          </Button>
        </div>
      ) : (
        <a
          href={item.href || '#'}
          data-test={item.label}
          className={classnames(item.type === 'image' && styles.imageLink)}
        >
          {item.type === 'image' && item.topLeftBadge && (
            <div className={styles.topLeftBadge}>
              <Markdown>{item.topLeftBadge}</Markdown>
            </div>
          )}
          {item.type === 'image' && item.topRightBadge && (
            <div className={styles.topRightBadge}>
              <Markdown>{item.topRightBadge}</Markdown>
            </div>
          )}
          {item.type === 'image' && <img src={item.src} />}
          <Markdown>{item.label as string}</Markdown>
          {hasChildren && <Arrow size="large" />}
        </a>
      )}

      {isSubMenuOpen && hasChildren && (
        <div className={styles.nextLevel}>
          <div className={classnames(styles.subMenuArea, styles.push)}>
            <Items
              depth={depth + 1}
              items={item.items}
              subMenuIndicator={subMenuIndicator}
              subMenuState={subMenuState}
              itemsClassName={className}
              onSubMenuChange={onSubMenuChange}
              coverImage={(item as MenuItemWithSubMenus).coverImageSrc}
              isRelative
            />
          </div>
        </div>
      )}
    </li>
  );
}

function Arrow({ dir, size }: { dir?: 'left'; size?: 'large' }) {
  return (
    <svg
      className={classnames(styles.arrow, styles[dir], styles[size])}
      viewBox="0 0 16 16"
      fill="none"
      height="1em"
      role="img"
      aria-label="chevronRight"
    >
      <path d="m5.675 2-1.05 1.05L9.575 8l-4.95 4.95L5.675 14l6-6-6-6Z" fill="currentColor"></path>
    </svg>
  );
}

function Control({ className, onClick, children }: { className: string; onClick: () => any; children: ReactNode }) {
  return (
    <button type="button" className={classnames(className, styles.control)} onClick={onClick}>
      {children}
    </button>
  );
}
