import { EmbeddedAuth, useAuth } from '@vyro-x/react-auth';
import { AuthArgs } from '@vyro-x/react-auth/lib/types';
import { useEffect, useState } from 'react';
import { useShowroom } from '../../services/showrooms/useShowroom';
import { useAttribution } from '../../services/useAttribution';
import Drawer from '../Drawer';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAuth?: (user: { id: string }) => any;
  action?: 'login' | 'signup';
  authArgs?: AuthArgs;
};

export default function AuthDrawer(props: Props) {
  const { isOpen, onClose, onAuth, action = 'signup', authArgs = {} } = props;

  const auth = useAuth();
  const showroom = useShowroom();

  const { clear, utm, ownerId } = useAttribution();

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <EmbeddedAuth
        action={action}
        onAuth={async (user) => {
          auth.checkIsAuthenticated();

          if (typeof onAuth === 'function') {
            await onAuth(user);
          }

          // clear utm from local storage
          clear();

          onClose();
        }}
        authArgs={{
          showroom_code: showroom.code,
          utm,
          owner_id: ownerId,
          ...authArgs,
        }}
      />
    </Drawer>
  );
}
