import { useEffect, useState } from 'react';
import { useShowroom } from '../../../../services/showrooms/useShowroom';
import { AiOutlinePhone } from 'react-icons/ai';
import styles from './index.module.scss';

export const PhoneUs = () => {
  const { phone } = useShowroom();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mql = window.matchMedia ? window.matchMedia('(max-width: 780px)') : null;

    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches);
    };

    mql?.addEventListener('change', handleMediaQueryChange);
    setIsMobile(mql?.matches);

    return () => {
      mql?.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div className={styles.phoneUs}>
      <a style={{ display: 'flex', alignItems: 'center' }} href={isMobile ? `tel:${phone}` : '/contact-us'}>
        <AiOutlinePhone className={styles.phoneIcon} />
        <div className={styles.phoneText}>Contact Us</div>
      </a>
    </div>
  );
};
