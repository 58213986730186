import { useEffect } from "react";

export function useOnClickOutside(_refs, handler) {
  const refs = Array.isArray(_refs) ? _refs : [_refs];

  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      const isDescendent = refs.some((ref) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return true;
        }
      });

      if (isDescendent) {
        return;
      }

      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [refs, handler]);
}
