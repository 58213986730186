import styles from './index.module.scss';
import classnames from 'classnames';
import FormField from '../FormField';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  name?: string;
  label?: React.ReactNode;
  type?: string;
  size?: 'large' | 'normal';
  isBorderless?: boolean;
  isGhost?: boolean;
  formFieldProps?: any;
  defaultValue?: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  suffixButton?: React.ReactNode;
  autoFocus?: boolean;
  isRounded?: boolean;
  isBorderThin?: boolean;
  helpText?: React.ReactNode;
  rules?: any;
  errors?: any;
  register?: any;
  inputProps?: any;
  value?: any;
  theme?: {
    layout: 'dark' | 'light';
    controls: 'dark' | 'light';
  };
} & Record<string, any>;

export default function Input(props: Props) {
  const {
    children,
    className,
    label,
    name,
    type = 'text',
    size = 'normal',
    isBorderless,
    isGhost,
    prefixIcon,
    suffixIcon,
    suffixButton,
    isRounded,
    isBorderThin,
    autoFocus,
    helpText,
    rules,
    errors,
    register,
    defaultValue,
    formFieldProps = {},
    inputProps = {},
    ...otherProps
  } = props;

  const hasErrors = errors && !!errors[name];

  const theme = {
    layout: 'light',
    controls: 'light',
    ...props.theme,
  };

  return (
    <FormField label={label} name={name} errors={errors} helpText={helpText} {...formFieldProps}>
      <div
        className={classnames(
          styles.input,
          hasErrors && styles.hasErrors,
          isRounded && styles.isRounded,
          isBorderThin && styles.isBorderThin,
          isBorderless && styles.isBorderless,
          isGhost && styles.isGhost,
          styles[size],
          styles[theme?.layout + '_' + theme?.controls],
          !!prefixIcon && styles.hasPrefixIcon,
          !!suffixIcon && styles.hasSuffixIcon,
        )}
      >
        {prefixIcon && <div className={classnames(styles.icon, styles.prefixIcon)}>{prefixIcon}</div>}

        <input
          id={name}
          name={name}
          type={type}
          defaultValue={defaultValue}
          autoFocus={autoFocus}
          {...(register ? register(name, rules) : {})}
          {...otherProps}
          {...inputProps}
        />

        {suffixIcon && <div className={classnames(styles.icon, styles.suffixIcon)}>{suffixIcon}</div>}

        {suffixButton && <div className={classnames(styles.button, styles.suffixButton)}>{suffixButton}</div>}
      </div>
    </FormField>
  );
}
