import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import Button from '../../../Button';
import Container from '../../../Container';
import AccountMenu from '../../components/AccountMenu';
import Logo from '../../components/Logo';
import HorizontalMenu from '../../components/HorizontalMenu';
import MenuToggle from '../../components/MenuToggle';
import Spacer from '../../components/Spacer';
import { ComponentBaseProps } from '../../types';
import { HeaderVariantProps } from '../types';
import styles from './index.module.scss';
import CollapsableMenu from '../../components/CollapsableMenu';

export default function StackedHeaderVariant(props: HeaderVariantProps) {
  const { isFluid } = props;
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const showroomSettings = useShowroomSettings(['header_cta']);

  const isMainMenuCollapsable = useMediaQuery({
    maxWidth: 880,
  });

  // Trigger a re-render when hydrates
  // The media query above doesn't render correctly on SSR
  // This is a known issue with Nextjs & react-responsive
  const [isClient, setIsClient] = useState(0);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(1);
    }
  }, []);

  const menuToggleRef = useRef(null);

  const componentBaseProps: ComponentBaseProps = {
    isMainMenuOpen,
    setIsMainMenuOpen,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    isMainMenuCollapsable,
    menuToggleRef,
  };

  return (
    <>
      <Spacer {...componentBaseProps} className={styles.spacer} />
      <header
        key={isClient}
        className={classnames(
          styles.stackedHeader,
          isMainMenuCollapsable && styles.isMainMenuCollapsable,
          (isAccountMenuOpen || isMainMenuOpen) && styles.isHeaderOverlayOpen,
        )}
      >
        <Container className={styles.container} isFluid={isFluid}>
          <Logo {...componentBaseProps} width="130px" className={styles.logo} />
          <div className={styles.menus}>
            <div className={styles.top}>
              <Button.Group>
                <AccountMenu {...componentBaseProps} />
                {showroomSettings.header_cta && (
                  <Button href={showroomSettings.header_cta.href} color="primary" size="small" className={styles.cta}>
                    {showroomSettings.header_cta.label} <FaArrowRight className="inlineIcon suffix" />
                  </Button>
                )}
              </Button.Group>
            </div>
            <div className={styles.bottom}>
              {!isMainMenuCollapsable && <HorizontalMenu isUnderlinedWhenActive size="small" {...componentBaseProps} />}
            </div>
          </div>
          <MenuToggle {...componentBaseProps} />
        </Container>
      </header>
      <CollapsableMenu {...componentBaseProps} />
    </>
  );
}
