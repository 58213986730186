import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { CollapsableMenuProps } from './types';
import BlockCollapsableMenu from './variants/Block';
import MultiLayerCollapsableMenu from './variants/MultiLayer';
import StandardCollapsableMenu from './variants/Standard';

export default function CollapsableMenu(props: CollapsableMenuProps) {
  const showroomSettings = useShowroomSettings(['collapsable_menu_variant']);
  switch (showroomSettings.collapsable_menu_variant) {
    case 'block':
      return <BlockCollapsableMenu {...props} />;
    case 'multiLayer':
      return <MultiLayerCollapsableMenu {...props} />;
    default:
      return <StandardCollapsableMenu {...props} />;
  }
}
