import classnames from 'classnames';
import { useRef, useState } from 'react';
import Image from 'next/image';
import Container from '../../../Container';
import AccountMenu from '../../components/AccountMenu';
import Logo from '../../components/Logo';
import Spacer from '../../components/Spacer';
import { ComponentBaseProps } from '../../types';
import Button from '../../../Button';
import { getImageCdnUrl } from '../../../../services/getImageCdnUrl';
import { useHeaderHeight } from '../../../../services/useHeaderHeight';
import { vehicles, upperHeaderLinks } from './utils';
import styles from './index.module.scss';
import CollapsableMenu from '../../components/CollapsableMenu';
import MenuToggle from '../../components/MenuToggle';
import { useAuth } from '@vyro-x/react-auth';

export default function MinimalHeaderVariantCupra() {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const { isAuthenticated } = useAuth();

  const menuToggleRef = useRef(null);

  const headerHeight = useHeaderHeight();

  const componentBaseProps: ComponentBaseProps = {
    isMainMenuOpen,
    setIsMainMenuOpen,
    isMainMenuCollapsable: true,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    menuToggleRef,
  };

  return (
    <>
      <Spacer {...componentBaseProps} className={styles.spacer} />
      <header
        className={classnames(
          styles.minimalHeader,
          (isAccountMenuOpen || isMainMenuOpen) && styles.isHeaderOverlayOpen,
        )}
        style={{ height: headerHeight }}
      >
        <div className={styles.upperHeader}>
          <Container className={styles.container} isFluid>
            {upperHeaderLinks.map((el, i) => (
              <Button key={i} href={el.href} isText>
                {el?.imageSrc && <Image src={el.imageSrc} alt={el.label} width={16} height={16} />}
                {el.label}
              </Button>
            ))}
          </Container>
        </div>
        <Container className={styles.container} isFluid>
          <Logo
            {...componentBaseProps}
            imageSrc={
              'https://ik.imagekit.io/vyro/Cupra-NZ_Static-Files/Logo/logo-cupra_wi3rCP4Hc.svg?updatedAt=1717373360917'
            }
            width="193px"
            className={classnames(styles.logo, styles.left)}
          />

          <Button className={styles.configuratorCta} isBordered color={'primary'} href={'/cupra/configurator'}>
            Configurator
          </Button>
          <Button
            className={styles.findGarageCta}
            color={'primary'}
            href={'https://www.cupraofficial.co.nz/services/locations'}
          >
            Find a CUPRA Garage
          </Button>
          <AccountMenu {...componentBaseProps} />
          <MenuToggle {...componentBaseProps} className={styles.menuToggle} iconClassName={styles.icon} />
        </Container>
      </header>
      <CollapsableMenu
        {...componentBaseProps}
        className={styles.cupraCollapsableMenu}
        navClassName={styles.cupraCollapsableMenuNav}
        itemsClassName={styles.cupraCollapsableMenuItem}
      />
    </>
  );
}

export type HeaderActionItemType = { imageSrc: string; label: string; href: string };

const HeaderVehicle = ({ imageSrc, label, href }: HeaderActionItemType) => {
  return (
    <div className={styles.headerVehicle}>
      <Button isText href={href}>
        <img src={getImageCdnUrl(imageSrc, {})} alt={label} />
        {label}
      </Button>
    </div>
  );
};
