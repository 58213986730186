import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../../../Button';
import Drawer from '../../../Drawer';
import Input from '../../../Input';
import styles from './index.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => any;
};

export const SearchOverlay = (props: Props) => {
  const { isOpen, onClose } = props;

  const form = useForm({
    defaultValues: { search: '' },
  });
  const { handleSubmit, register } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ search }) => {
    setIsLoading(true);

    location.href = `/vehicles?search=${search}`;
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const input = document.getElementById('search-input') as HTMLInputElement;
        if (input && typeof input.focus === 'function') {
          input.focus();
        }
      }, 300);
    }
  }, [isOpen]);

  return (
    <Drawer size="large" isOpen={isOpen} onClose={onClose} className={styles.searchOverlay}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>Search</h2>

          <Input
            id="search-input"
            register={register}
            isRounded
            size="large"
            name="search"
            placeholder="Search cars by make, model, colour, etc."
            suffixButton={
              <Button type="submit" color="primary" isLoading={isLoading}>
                Go
              </Button>
            }
          />
        </form>
      </FormProvider>
    </Drawer>
  );
};
