import styles from './index.module.scss';
import classnames from 'classnames';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  label: React.ReactNode;
  name: string;
  helpText?: React.ReactNode;
  description?: React.ReactNode;
  errors?: string[] | null;
  isInline?: boolean;
} & Record<string, any>;

export default function FormField(props: Props) {
  const { children, label, name, helpText, description, errors, className, isInline = false, ...otherProps } = props;

  const [isErrorOpen, setIsErrorOpen] = useState(false);

  const error = !!errors && get(errors, name);

  useEffect(() => {
    setIsErrorOpen(true);
  }, [error]);

  return (
    <div
      className={classnames(styles.formField, !!error && styles.hasErrors, isInline && styles.isInline, className)}
      {...otherProps}
    >
      <div className={styles.labelAndInput}>
        {label && <FormField.Label htmlFor={name}>{label}</FormField.Label>}
        {description && <div className={styles.description}>{description}</div>}
        <div className={styles.input}>{children}</div>
      </div>
      {helpText && <div className={styles.helpText}>{helpText}</div>}
      {!!error && isErrorOpen && (
        <div className={styles.error}>
          {error.message}

          {isInline && (
            <button tabIndex={-1} type="button" onClick={() => setIsErrorOpen(false)} className={styles.closeError}>
              <AiOutlineClose />
            </button>
          )}
        </div>
      )}
    </div>
  );
}

type FormFieldLabelProps = {
  htmlFor: string;
  children: React.ReactNode;
};

FormField.Label = function Label(props: FormFieldLabelProps) {
  const { htmlFor, children } = props;

  return (
    <label htmlFor={htmlFor} className={styles.label}>
      {children}
    </label>
  );
};
