import Link from 'next/link';
import { useShowroom } from '../../../../services/showrooms/useShowroom';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import styles from './index.module.scss';
import classnames from 'classnames';
import { ComponentBaseProps } from '../../types';

export type Props = {
  width?: string;
  imageSrc?: string;
} & ComponentBaseProps;

export default function Logo(props: Props) {
  const { className, width = '100px' } = props;

  const showroom = useShowroom();
  const showroomSettings = useShowroomSettings([
    'header_back_to_main_site_url',
    'horizontal_logo',
    'header_logo_width',
  ]);

  return (
    <Link href={showroomSettings.header_back_to_main_site_url || '/'}>
      <a className={classnames(styles.logo, className)}>
        <div className={styles.logoImg}>
          <img
            src={props.imageSrc || showroomSettings.horizontal_logo}
            alt={showroom.name}
            style={{ width: showroomSettings.header_logo_width || width }}
          />
        </div>
      </a>
    </Link>
  );
}
