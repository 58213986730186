import { useShowroomSettings } from '../../services/showrooms/useShowroomSettings';
import CbaHeaderVariant from './variants/Cba';
import HeaderVariantLotus from './variants/Lotus';
import MinimalHeaderVariant from './variants/Minimal';
import MinimalHeaderVariantCupra from './variants/MinimalCupra';
import StackedHeaderVariant from './variants/Stacked';
import StandardHeaderVariant from './variants/Standard';
import { HeaderVariantProps } from './variants/types';

export interface Props extends HeaderVariantProps {}

export default function Header(props: Props) {
  const showroomSettings = useShowroomSettings(['header_variant']);

  switch (showroomSettings.header_variant) {
    case 'stacked':
      return <StackedHeaderVariant {...props} />;
    case 'minimalCupra':
      return <MinimalHeaderVariantCupra />;
    case 'lotus':
      return <HeaderVariantLotus {...props} />;
    case 'minimal':
      return <MinimalHeaderVariant {...props} />;
    case 'cba':
      return <CbaHeaderVariant {...props} />;
    default:
      return <StandardHeaderVariant {...props} />;
  }
}
