import classnames from 'classnames';
import React from 'react';
import { GrClose } from 'react-icons/gr';
import ReactModal from 'react-modal';
import styles from './index.module.scss';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: () => any;
  position?: 'left' | 'right';
  size?: 'small' | 'normal' | 'large';
  showOverlay?: boolean;
} & Record<string, any>;

export default function Drawer(props: Props) {
  const {
    children,
    className,
    isOpen,
    onClose,
    size = 'normal',
    position = 'right',
    showOverlay = true,
    ...otherProps
  } = props;

  return (
    <ReactModal
      className={classnames(styles.content, className)}
      closeTimeoutMS={800}
      overlayClassName={{
        base: classnames(styles.overlay, showOverlay && styles.showOverlay, styles[position], styles[size]),
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose,
      }}
      shouldCloseOnOverlayClick={showOverlay}
      bodyOpenClassName="isModalOpen"
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      {...otherProps}
    >
      <div className={styles.innerContent}>{children}</div>
      <button className={styles.close} type="button" aria-label="Close modal" onClick={() => onClose()}>
        <GrClose />
      </button>
    </ReactModal>
  );
}

Drawer.Title = function Title({ children }: { children: React.ReactNode }) {
  return <h3 className={styles.title}>{children}</h3>;
};
