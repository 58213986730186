import classnames from 'classnames';
import { useRef, useState } from 'react';
import Container from '../../../Container';
import AccountMenu from '../../components/AccountMenu';
import CollapsableMenu from '../../components/CollapsableMenu';
import Logo from '../../components/Logo';
import MenuToggle from '../../components/MenuToggle';
import { ComponentBaseProps } from '../../types';
import { HeaderVariantProps } from '../types';
import styles from './index.module.scss';

export default function MinimalHeaderVariant(props: HeaderVariantProps) {
  const { isFluid } = props;

  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const menuToggleRef = useRef(null);

  const componentBaseProps: ComponentBaseProps = {
    isMainMenuOpen,
    setIsMainMenuOpen,
    isMainMenuCollapsable: true,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    menuToggleRef,
  };

  return (
    <>
      <div className={styles.preHeader}>
        <Container>Polestar is operated in New Zealand by The Giltrap Group.</Container>
      </div>

      <header
        className={classnames(
          styles.minimalHeader,
          (isAccountMenuOpen || isMainMenuOpen) && styles.isHeaderOverlayOpen,
        )}
      >
        <Container className={styles.container} isFluid={isFluid}>
          <MenuToggle {...componentBaseProps} className={styles.menuToggle} />
          <AccountMenu {...componentBaseProps} />
          <Logo {...componentBaseProps} width="130px" className={styles.logo} />
        </Container>
      </header>
      <CollapsableMenu {...componentBaseProps} />
    </>
  );
}
