import { useShowroomSettings } from '../showrooms/useShowroomSettings';
import { useMediaQuery } from 'react-responsive';
import { useKioskMode } from '../useKioskMode';

export const useHeaderHeight = (): number => {
  const showroomSettings = useShowroomSettings(['header_variant']);

  const isKioskMode = useKioskMode();
  const isLaptop: boolean = useMediaQuery({ query: '(min-width: 1016px)' });
  if (isKioskMode) return 0;
  switch (showroomSettings.header_variant) {
    case 'lotus':
      return 80;
    case 'minimalCupra':
      return isLaptop ? 120 : 80;
    default:
      return 76;
  }
};
