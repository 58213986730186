import Link from 'next/link';
import MarkdownToJsx from 'markdown-to-jsx';
import styles from './index.module.scss';
import MarkdownCaption from './elements/MakdownCaption';
import MarkdownIcon from './elements/MakdownIcon';
import { template, templateSettings } from 'lodash';
import classnames from 'classnames';
import Button from '../Button';

type Props = {
  className?: string;
  children: string;
  /** Variables which will be used to replace moustache tokens in the markdown. E.g. {{foo.bar}} */
  variables?: Record<string, any>;
  allowLinks?: boolean;
};
export default function Markdown(props: Props) {
  const { children: md, className, variables, allowLinks = true } = props;

  if (!md) {
    return null;
  }

  const parseWithVars = (value: string) => {
    // See: https://lodash.com/docs/4.17.15#template
    templateSettings.interpolate = /{{([\s\S]+?)}}/g;

    return template(value)(variables);
  };

  return (
    <div className={classnames(styles.markdown, className)}>
      <MarkdownToJsx
        children={parseWithVars(md)}
        options={{
          overrides: {
            a: {
              component: ({ href, children, ...rest }) =>
                allowLinks ? (
                  <Link href={href}>
                    <a {...rest}>{children}</a>
                  </Link>
                ) : (
                  <>
                    {children} ({href})
                  </>
                ),
            },
            Button: ({ href, color, size, children, ...otherProps }) => {
              return allowLinks ? (
                <Button href={href} color={color} size={size} {...otherProps}>
                  {children}
                </Button>
              ) : null;
            },
            Caption: {
              component: MarkdownCaption,
            },
            Icon: {
              component: MarkdownIcon,
            },
          },
        }}
      />
    </div>
  );
}
