import classnames from 'classnames';
import { ComponentBaseProps } from '../../types';
import styles from './index.module.scss';
import { useHeaderHeight } from '../../../../services/useHeaderHeight';

export type Props = {} & ComponentBaseProps;

export default function Spacer(props: Props) {
  const { className } = props;

  const headerHeight = useHeaderHeight();

  return <div aria-hidden="true" className={classnames(styles.spacer, className)} style={{ height: headerHeight }} />;
}
