import classnames from 'classnames';
import styles from './index.module.scss';
import { ComponentBaseProps } from '../../types';
import { ReactNode } from 'react';

export type Props = {
  label?: ReactNode;
  iconClassName?: string;
} & ComponentBaseProps;

export default function MenuToggle(props: Props) {
  const { className, iconClassName, label, isMainMenuCollapsable, isMainMenuOpen, setIsMainMenuOpen, menuToggleRef } =
    props;

  if (!isMainMenuCollapsable) {
    return null;
  }

  return (
    <button
      ref={menuToggleRef}
      className={classnames(styles.menuToggle, isMainMenuOpen && styles.isOpen, className)}
      type="button"
      aria-label="Open / close menu"
      onClick={() => setIsMainMenuOpen(!isMainMenuOpen)}
    >
      <div className={classnames(styles.icon, iconClassName)}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {label && <div className={styles.label}>{label}</div>}
    </button>
  );
}
