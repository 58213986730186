import classnames from 'classnames';
import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Container from '../../../Container';
import AccountMenu from '../../components/AccountMenu';
import Logo from '../../components/Logo';
import CollapsableMenu from '../../components/CollapsableMenu';
import MenuToggle from '../../components/MenuToggle';
import Spacer from '../../components/Spacer';
import { ComponentBaseProps } from '../../types';
import { HeaderVariantProps } from '../types';
import styles from './index.module.scss';

export default function HeaderVariantLotus(props: HeaderVariantProps) {
  const { isFluid, fillBackground } = props;

  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const menuToggleRef = useRef(null);

  const componentBaseProps: ComponentBaseProps = {
    isMainMenuOpen,
    setIsMainMenuOpen,
    isMainMenuCollapsable: true,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    menuToggleRef,
  };

  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const logoWidth = isTablet ? '150px' : '170px';

  return (
    <>
      <Spacer {...componentBaseProps} className={styles.spacer} />
      <header
        className={classnames(
          styles.lotusHeader,
          (isAccountMenuOpen || isMainMenuOpen) && styles.isHeaderOverlayOpen,
          fillBackground && styles.fillBackground,
        )}
      >
        <Container className={styles.container} isFluid={isFluid}>
          <MenuToggle {...componentBaseProps} className={styles.menuToggle} />
          <Logo {...componentBaseProps} width={logoWidth} className={styles.logo} />
          <AccountMenu {...componentBaseProps} itemsClassName={styles.lotusAccountItems} className={styles.lotus} />
        </Container>
      </header>
      <CollapsableMenu {...componentBaseProps} />
    </>
  );
}
