import styles from './index.module.scss';
import classnames from 'classnames';

type MarkdownCaptionProps = {
  src: string;
  isVideo?: boolean;
  type?: 'image' | 'video';
  isReverse?: boolean;
  children: React.ReactNode;
  className?: string;
};

export default function MarkdownCaption(props: MarkdownCaptionProps) {
  const { src, isReverse = false, children, isVideo } = props;

  return (
    <figure
      className={classnames(
        styles.markdownCaption,
        isReverse && styles.isReverse,
        props.className && styles[props.className],
      )}
    >
      {isVideo ? (
        <video controls muted autoPlay loop className={styles.video} src={src}></video>
      ) : (
        <img className={styles.img} src={src} />
      )}
      <figcaption className={styles.caption}>{children}</figcaption>
    </figure>
  );
}
