import { useRouter } from 'next/router';

/**
 * Checks to see if the current page is in kiosk mode
 *
 * In kiosk mode, the page is displayed without any navigation or other UI elements
 *
 * @returns Whether or not the current page is in kiosk mode
 */
export const useKioskMode = (): boolean => {
  const router = useRouter();

  // legacy support for ?device=event
  if (router.query.device === 'event') {
    return true;
  }

  if (router.query.mode === 'kiosk') {
    return true;
  }

  return false;
};
