import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useShowroomSettings } from '../../../../../../services/showrooms/useShowroomSettings';
import { MenuItem } from '../../../../../../types/menu';
import { HorizontalMenuProps } from '../../types';
import styles from './index.module.scss';

export default function StanardHorizontalMenu(props: HorizontalMenuProps) {
  const { className, size, isUnderlinedWhenActive } = props;

  const showroomSettings = useShowroomSettings(['header_menu', 'header_menu_alignment']);

  const items: Array<MenuItem> = showroomSettings.header_menu || [];

  return (
    <nav
      className={classnames(
        styles.standardHorizontalMenu,
        isUnderlinedWhenActive && styles.isUnderlinedWhenActive,
        styles[showroomSettings.header_menu_alignment],
        styles[size],
        className,
      )}
    >
      <ul>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </ul>
    </nav>
  );
}

type MenuItemProps = {
  item: MenuItem;
};

function Item(props: MenuItemProps) {
  const { item } = props;

  const hasChildren = !!item.items?.length;

  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();

  return (
    <li
      className={classnames(
        styles.item,
        isOpen && hasChildren && styles.isOpen,
        router.asPath.startsWith(item.href) && styles.isActive,
      )}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <a href={item.href || '#'} data-test={item.label}>
        {item.label}
        {hasChildren && <BsChevronDown aria-label="Hover or click to see child menu" className={styles.arrow} />}
      </a>

      {hasChildren && (
        <ul>
          {item.items.map((child, i) => (
            <Item key={i} item={child} />
          ))}
        </ul>
      )}
    </li>
  );
}
