import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Container from '../../../Container';
import AccountMenu from '../../components/AccountMenu';
import Logo from '../../components/Logo';
import { PhoneUs } from '../../components/PhoneUs';
import HorizontalMenu from '../../components/HorizontalMenu';
import CollapsableMenu from '../../components/CollapsableMenu';
import MenuToggle from '../../components/MenuToggle';
import Spacer from '../../components/Spacer';
import { ComponentBaseProps } from '../../types';
import { HeaderVariantProps } from '../types';
import styles from './index.module.scss';
import classnames from 'classnames';

export default function StandardHeaderVariant(props: HeaderVariantProps) {
  const { isFluid } = props;

  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const isMainMenuCollapsable = useMediaQuery({
    maxWidth: 880,
  });

  // Trigger a re-render when hydrates
  // The media query above doesn't render correctly on SSR
  // This is a known issue with Nextjs & react-responsive
  const [isClient, setIsClient] = useState(0);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(1);
    }
  }, []);

  const menuToggleRef = useRef(null);

  const componentBaseProps: ComponentBaseProps = {
    isMainMenuOpen,
    setIsMainMenuOpen,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    isMainMenuCollapsable,
    menuToggleRef,
  };

  return (
    <>
      <Spacer {...componentBaseProps} className={styles.spacer} />
      <header
        key={isClient}
        className={classnames(
          styles.standardHeader,
          (isAccountMenuOpen || isMainMenuOpen) && styles.isHeaderOverlayOpen,
        )}
      >
        <Container className={styles.container} isFluid={isFluid}>
          <Logo {...componentBaseProps} />
          {!isMainMenuCollapsable && <HorizontalMenu {...componentBaseProps} />}
          <CollapsableMenu {...componentBaseProps} />
          <AccountMenu {...componentBaseProps} />
          <PhoneUs />
          <MenuToggle {...componentBaseProps} />
        </Container>
      </header>
    </>
  );
}
