import styles from './index.module.scss';
import { BsFuelPump } from 'react-icons/bs';
import { MdOutlineCo2 } from 'react-icons/md';
import { PiPlugChargingLight } from 'react-icons/pi';
import { PiLightning } from 'react-icons/pi';

type PropTypes = {
  src: 'fuel' | 'co2' | 'plug' | 'lightning';
  size?: number | string;
  label?: string;
};

export default function MarkdownIcon(props: PropTypes) {
  const { label, ...rest } = props;
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <RenderIcon {...rest} />
      </div>
      <label>{label}</label>
    </div>
  );
}

const RenderIcon = (props: PropTypes) => {
  const { src, size } = props;

  switch (src) {
    case 'fuel':
      return <BsFuelPump size={size} />;
    case 'co2':
      return <MdOutlineCo2 size={size} />;
    case 'plug':
      return <PiPlugChargingLight size={size} />;
    case 'lightning':
      return <PiLightning size={size} />;
    default:
      return <></>;
  }
};
