import classnames from 'classnames';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import { useShowroomSettings } from '../../../../../../services/showrooms/useShowroomSettings';
import { useDebounce } from '../../../../../../services/useDebounce';
import { useOnClickOutside } from '../../../../../../services/useOnClickOutside';
import { MenuItem } from '../../../../../../types/menu';
import { CollapsableMenuProps } from '../../types';
import styles from './index.module.scss';

export default function StanardCollapsableMenu(props: CollapsableMenuProps) {
  const { className, menuToggleRef, size, isMainMenuOpen, setIsMainMenuOpen } = props;

  const showroomSettings = useShowroomSettings(['header_menu']);

  const items: Array<MenuItem> = showroomSettings.header_menu || [];

  const ref = useRef(null);
  useOnClickOutside([ref, menuToggleRef], () => {
    setIsMainMenuOpen(false);
  });

  const router = useRouter();
  useEffect(() => {
    setIsMainMenuOpen(false);
  }, [router.asPath]);

  const [subMenuState, setSubMenuState] = useState([]);
  const isSubMenuOpen = Object.values(subMenuState).some((isOpen) => isOpen);

  return (
    <div
      className={classnames(
        styles.standardCollapsableMenu,
        isMainMenuOpen && styles.isOpen,
        isSubMenuOpen && styles.isSubMenuOpen,
        styles[size],
        className,
      )}
      ref={ref}
    >
      <div className={styles.overlay} aria-hidden="true" onClick={() => setIsMainMenuOpen(false)} />

      <nav className={styles.nav}>
        <Control aria-label="Back one level" className={styles.back} onClick={() => setSubMenuState([])}>
          <Arrow dir={'left'} size={'large'} />
        </Control>

        <Control aria-label="Close" className={styles.close} onClick={() => setIsMainMenuOpen(false)}>
          <GrClose />
        </Control>

        <ul>
          {items.map((item, i) => (
            <Item
              key={i}
              item={item}
              onSubMenuChange={(isOpen) => {
                setSubMenuState((prev) => ({
                  ...prev,
                  [i]: isOpen,
                }));
              }}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
}

type ItemProps = {
  item: MenuItem;
  onSubMenuChange?: (isOpen: boolean) => void;
};

function Item(props: ItemProps) {
  const { item, onSubMenuChange } = props;

  const hasChildren = item.items?.length > 0;

  const [isOpen, setIsOpen] = useState(false);
  const [debouncedIsOpen, setIsOpenImmediate] = useDebounce(isOpen, 300);

  useEffect(() => {
    if (onSubMenuChange) {
      onSubMenuChange(debouncedIsOpen);
    }
  }, [debouncedIsOpen]);

  const router = useRouter();

  const ref = useRef(null);
  useOnClickOutside([ref], () => {
    setIsOpen(false);
  });

  return (
    <li
      ref={ref}
      className={classnames(
        styles.item,
        debouncedIsOpen && hasChildren && styles.isOpen,
        item.isEmphasised && styles.isEmphasised,
        router.pathname.startsWith(item.href) && styles.isActive,
      )}
      onClick={() => hasChildren && setIsOpen(!isOpen)}
    >
      <a href={item.href || '#'} data-test={item.label}>
        {item.label}
        {hasChildren && <Arrow />}
      </a>

      {hasChildren && (
        <ul>
          {item.items.map((child, i) => (
            <Item key={i} item={child} />
          ))}
        </ul>
      )}
    </li>
  );
}

function Arrow({ dir, size }: { dir?: 'left'; size?: 'large' }) {
  return (
    <svg
      className={classnames(styles.arrow, styles[dir], styles[size])}
      viewBox="0 0 16 16"
      fill="none"
      height="1em"
      role="img"
      aria-label="chevronRight"
    >
      <path d="m5.675 2-1.05 1.05L9.575 8l-4.95 4.95L5.675 14l6-6-6-6Z" fill="currentColor"></path>
    </svg>
  );
}

function Control({ className, onClick, children }: { className: string; onClick: () => any; children: ReactNode }) {
  return (
    <button type="button" className={classnames(className, styles.control)} onClick={onClick}>
      {children}
    </button>
  );
}
