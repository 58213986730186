import classnames from 'classnames';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import { useShowroomSettings } from '../../../../../../services/showrooms/useShowroomSettings';
import { useOnClickOutside } from '../../../../../../services/useOnClickOutside';
import { MenuItem } from '../../../../../../types/menu';
import { CollapsableMenuProps } from '../../types';
import styles from './index.module.scss';

export default function BlockCollapsableMenu(props: CollapsableMenuProps) {
  const { className, menuToggleRef, size, isMainMenuOpen, setIsMainMenuOpen } = props;

  const showroomSettings = useShowroomSettings(['header_menu']);

  const items: Array<MenuItem> = showroomSettings.header_menu || [];

  const ref = useRef(null);
  useOnClickOutside([ref, menuToggleRef], () => {
    setIsMainMenuOpen(false);
  });

  const router = useRouter();
  useEffect(() => {
    setIsMainMenuOpen(false);
  }, [router.asPath]);

  const opacityIncrement = 1 / items.length;
  const getOpacity = (i: number) => {
    return opacityIncrement * i;
  };

  return (
    <div
      className={classnames(styles.blockCollapsableMenu, isMainMenuOpen && styles.isOpen, styles[size], className)}
      ref={ref}
    >
      <div className={styles.overlay} aria-hidden="true" onClick={() => setIsMainMenuOpen(false)} />

      <nav className={styles.nav}>
        <Control className={styles.close} onClick={() => setIsMainMenuOpen(false)}>
          <AiOutlineClose />
        </Control>

        <ul>
          {items.map((item, i) => (
            <Item key={i} item={item} opacity={getOpacity(i)} />
          ))}
        </ul>
      </nav>
    </div>
  );
}

type ItemProps = {
  item: MenuItem;
  opacity: number;
};

function Item(props: ItemProps) {
  const { item, opacity } = props;

  const router = useRouter();

  return (
    <li
      className={classnames(styles.item, router.pathname.startsWith(item.href) && styles.isActive)}
      style={{ background: `rgba(91, 98, 109, ${opacity})` }}
    >
      <a href={item.href || '#'} data-test={item.label}>
        {item.label}
      </a>
    </li>
  );
}

function Control({ className, onClick, children }: { className: string; onClick: () => any; children: ReactNode }) {
  return (
    <button type="button" className={classnames(className, styles.control)} onClick={onClick}>
      {children}
    </button>
  );
}
