import styles from "./index.module.scss";
import classnames from "classnames";

export interface Props {
  children?: React.ReactNode;
  isWrapped?: boolean;
  isFlex?: boolean;
  isFluid?: boolean;
  isFlush?: boolean;
  className?: string;
  size?: "small" | "medium" | "large" | "normal";
}

export default function Container(props: Props) {
  const {
    children,
    isWrapped,
    isFlex,
    isFluid,
    isFlush,
    size = "normal",
    className,
  } = props;

  return (
    <div
      className={classnames(
        styles.container,
        className,
        styles[size],
        isWrapped && styles.isWrapped,
        isFlex && styles.isFlex,
        isFluid && styles.isFluid,
        isFlush && styles.isFlush
      )}
    >
      {children}
    </div>
  );
}
