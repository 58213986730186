import { useAuth } from '@vyro-x/react-auth';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineCar, AiOutlineLock } from 'react-icons/ai';
import { IoMdSearch } from 'react-icons/io';
import { useMediaQuery } from 'react-responsive';
import AuthDrawer, { Props as AuthDrawerProps } from '../../../AuthDrawer';
import Container from '../../../Container';
import CollapsableMenu from '../../components/CollapsableMenu';
import HorizontalMenu from '../../components/HorizontalMenu';
import Logo from '../../components/Logo';
import MenuToggle from '../../components/MenuToggle';
import { SearchOverlay } from '../../components/SearchOverlay';
import Spacer from '../../components/Spacer';
import { ComponentBaseProps } from '../../types';
import { HeaderVariantProps } from '../types';
import styles from './index.module.scss';

export default function CbaHeaderVariant(props: HeaderVariantProps) {
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const auth = useAuth();
  const [isAuthDrawerOpen, setIsAuthDrawerOpen] = useState(false);

  const isMainMenuCollapsable = useMediaQuery({
    maxWidth: 880,
  });

  // Trigger a re-render when hydrates
  // The media query above doesn't render correctly on SSR
  // This is a known issue with Nextjs & react-responsive
  const [isClient, setIsClient] = useState(0);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(1);
    }
  }, []);

  const menuToggleRef = useRef(null);

  const componentBaseProps: ComponentBaseProps = {
    isMainMenuOpen,
    setIsMainMenuOpen,
    isAccountMenuOpen,
    setIsAccountMenuOpen,
    isMainMenuCollapsable,
    menuToggleRef,
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <>
      <Spacer {...componentBaseProps} className={styles.spacer} />

      <header
        key={isClient}
        className={classnames(styles.header, (isAccountMenuOpen || isMainMenuOpen) && styles.isHeaderOverlayOpen)}
      >
        <Container className={styles.container} isFluid>
          <MenuToggle className={styles.menuToggle} {...componentBaseProps} />
          <CollapsableMenu {...componentBaseProps} />
          <div className={styles.logoAndMenuLockup}>
            <Logo className={styles.logo} {...componentBaseProps} />
            {!isMainMenuCollapsable && <HorizontalMenu isUnderlinedWhenActive {...componentBaseProps} />}
          </div>
          <div className={styles.searchAndAccountLockup}>
            <button
              onClick={() => setIsSearchOpen(true)}
              className={classnames(styles.button, styles.search)}
              type="button"
              aria-label="Search..."
            >
              <IoMdSearch />
            </button>

            <button
              className={classnames(styles.button, styles.login)}
              type="button"
              onClick={() => {
                if (auth.isAuthenticated) {
                  window.location.href = '/account';
                } else {
                  setIsAuthDrawerOpen(true);
                }
              }}
            >
              {auth.isAuthenticated ? (
                <>
                  <AiOutlineCar className="inlineIcon" /> My car
                </>
              ) : (
                <>
                  <AiOutlineLock className="inlineIcon" />
                  Log on
                </>
              )}
            </button>
          </div>
        </Container>
      </header>

      <SearchOverlay isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />

      <AuthDrawer action={'login'} isOpen={isAuthDrawerOpen} onClose={() => setIsAuthDrawerOpen(false)} />
    </>
  );
}
